<template>
  <div>
    <core-page-title />

    <v-container class="mt-3">
      <v-progress-linear
        v-if="loadingTypesOfApportionments"
        color="primary"
        absolute
        top
        indeterminate
      />

      <v-row>
        <v-col>
          <v-btn
            v-for="typeOfApportionment in typesOfApportionments"
            :key="typeOfApportionment.id"
            :color="typeOfApportionment.color"
            class="mr-2"
            dark
            @click="openDialog(typeOfApportionment)"
          >
            <v-icon
              left
              v-text="'mdi-plus'"
            />
            {{ typeOfApportionment.description }}
          </v-btn>


        </v-col>
        <v-col
          class="text-right"
        >
          <v-btn
            class="mr-2"
            dark
            outlined
            color="info"
            @click="showDialogFilters = true"
          >
            <v-icon
              v-text="'mdi-filter'"
              left
            />
            Pesquisar
          </v-btn>
        </v-col>
      </v-row>

      <v-row class="mt-4">
        <v-col>
          <v-sheet elevation="1">
            <v-data-table
              disable-sort
              :server-items-length="count"
              :loading="loading"
              :headers="headers"
              :items="apportionments"
              :options.sync="pagination"
              :items-per-page="20"
              :footer-props="{
                'items-per-page-options': [10, 20, 50, 75]
              }"
              @update:options="loadApportionments"
            >
              <template
                v-slot:[`item.date`]="{ item }"
              >
                {{ item.date | date }}
              </template>

              <template
                v-slot:[`item.typeOfApportionment.description`]="{ item }"
              >
                <v-chip
                  :color="item.typeOfApportionment.color"
                  label
                  outlined
                  small
                >
                  {{ item.typeOfApportionment.description }}
                </v-chip>
              </template>

              <template
                v-slot:[`item.estimatedValue`]="{ item }"
              >
                {{ item.estimatedValue | currency }}
              </template>

              <template
                v-slot:[`item.realizedValue`]="{ item }"
              >
                {{ item.realizedValue | currency }}
              </template>

              <template
                v-slot:[`item.actions`]="{ item }"
                class="text-right"
              >
                <v-btn
                  icon
                  small
                  :disabled="item.smsSent"
                  @click="openDialogSendSms(item)"
                >
                  <v-icon
                    v-text="'mdi-message-text'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                  @click="openDialog(item.typeOfApportionment, item)"
                >
                  <v-icon
                    v-text="'mdi-pencil'"
                    small
                  />
                </v-btn>
                <v-btn
                  icon
                  small
                >
                  <v-icon
                    v-text="'mdi-delete'"
                    small
                    @click="clickDelete(item)"
                  />
                </v-btn>
              </template>
            </v-data-table>
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>

    <dialog-apportionment
      v-model="showDialog"
      :type-of-apportionment="selectedTypeOfApportionment"
      :apportionment="selectedApportionment"
      @save="loadApportionments"
    />

    <dialog-delete
      v-model="showDialogDelete"
      :loading="loadingDelete"
      @confirm="deleteItem"
    />

    <dialog-filters
      v-model="showDialogFilters"
      @filter="filter"
    />

    <dialog-send-sms
      v-model="showDialogSendSms"
      :apportionment="selectedApportionmentForSms"
      @send="loadApportionments"
    />

  </div>
</template>

<script>
  import apportionmentsApi from '@/api/apportionments'
  import typesOfApportionmentsApi from '@/api/types-of-apportionments'

  export default {

    components: {
      DialogApportionment: () => import('@/components/dialog/apportionments/DialogApportionment'),
      DialogDelete: () => import('@/components/dialog/DialogDelete'),
      DialogFilters: () => import('@/views/pages/apportionments/ApportionmentsListFilters'),
      DialogSendSms: () => import('@/components/dialog/apportionments/DialogApportionmentSendSms'),
    },

    data () {
      return {
        loading: false,
        loadingTypesOfApportionments: false,
        loadingDelete: false,
        count: 0,
        headers: [
          { text: 'Data', value: 'date' },
          { text: 'Tipo', value: 'typeOfApportionment.description' },
          { text: 'Estimado', value: 'estimatedValue' },
          { text: 'Realizado', value: 'realizedValue' },
          { text: '', value: 'actions', align: 'right' },
        ],
        apportionments: [],
        typesOfApportionments: [],
        pagination: {},
        showDialog: false,
        showDialogDelete: false,
        showDialogFilters: false,
        showDialogSendSms: false,
        selectedTypeOfApportionment: {
          id: null,
          color: 'info',
        },
        selectedApportionment: null,
        selectedApportionmentForSms: null,
        idToDelete: null,
        filters: {}
      }
    },

    mounted () {
      this.loadTypesOfApportionments()
    },

    methods: {
      async loadApportionments () {
        try {
          this.loading = true

          const response = await apportionmentsApi.list({
            limit: this.pagination.itemsPerPage,
            offset: (this.pagination.page * this.pagination.itemsPerPage) - this.pagination.itemsPerPage,
            ...this.filters
          })

          this.apportionments = response.data.apportionments
          this.count = response.data.count
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loading = false
        }
      },

      async loadTypesOfApportionments () {
        try {
          this.loadingTypesOfApportionments = true

          const responseTypesOfApportionments = await typesOfApportionmentsApi.list()

          this.typesOfApportionments = responseTypesOfApportionments.data.typesOfApportionments
        } catch (e) {
          console.error(e)
        } finally {
          this.loadingTypesOfApportionments = false
        }
      },

      openDialog (typeOfApportionment, apportionment = null) {
        this.showDialog = true
        this.selectedTypeOfApportionment = typeOfApportionment
        this.selectedApportionment = apportionment
      },

      openDialogSendSms (apportionment = null) {
        this.showDialogSendSms = true
        this.selectedApportionmentForSms = apportionment
      },

      filter (filters) {
        this.showDialogFilters = false
        this.filters = filters
        this.loadApportionments()
      },

      clickDelete (item) {
        this.idToDelete = item.id
        this.showDialogDelete = true
      },

      async deleteItem () {
        try {
          this.loadingDelete = true

          await apportionmentsApi.delete(this.idToDelete)

          this.loadApportionments()

          this.$snackbar.show({ color: 'success', message: this.$messages._('delete_success') })
        } catch (e) {
          this.$snackbar.show({ color: 'error', message: this.$apiError._(e) })
        } finally {
          this.loadingDelete = false
          this.showDialogDelete = false
        }
      },
    },

  }
</script>
