import axios from '@/plugins/axios'

export default {

  async list (data) {
    const param = new URLSearchParams(data).toString()
    return await axios.get(`apportionments?${param}`)
  },

  async get (id) {
    return await axios.get(`apportionments/${id}`)
  },

  async insert (data) {
    return await axios.post('apportionments', data)
  },

  async update (id, data) {
    return await axios.put(`apportionments/${id}`, data)
  },

  async delete (id) {
    return await axios.delete(`apportionments/${id}`)
  },

  async sendSms (id, data) {
    return await axios.post(`apportionments/${id}/send-sms`, data)
  },

  async search (data) {
    const param = new URLSearchParams(data).toString()
    return await axios.get(`apportionments/search?${param}`)
  },

}
