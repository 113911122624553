var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('core-page-title'),_c('v-container',{staticClass:"mt-3"},[(_vm.loadingTypesOfApportionments)?_c('v-progress-linear',{attrs:{"color":"primary","absolute":"","top":"","indeterminate":""}}):_vm._e(),_c('v-row',[_c('v-col',_vm._l((_vm.typesOfApportionments),function(typeOfApportionment){return _c('v-btn',{key:typeOfApportionment.id,staticClass:"mr-2",attrs:{"color":typeOfApportionment.color,"dark":""},on:{"click":function($event){return _vm.openDialog(typeOfApportionment)}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-plus')}}),_vm._v(" "+_vm._s(typeOfApportionment.description)+" ")],1)}),1),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{staticClass:"mr-2",attrs:{"dark":"","outlined":"","color":"info"},on:{"click":function($event){_vm.showDialogFilters = true}}},[_c('v-icon',{attrs:{"left":""},domProps:{"textContent":_vm._s('mdi-filter')}}),_vm._v(" Pesquisar ")],1)],1)],1),_c('v-row',{staticClass:"mt-4"},[_c('v-col',[_c('v-sheet',{attrs:{"elevation":"1"}},[_c('v-data-table',{attrs:{"disable-sort":"","server-items-length":_vm.count,"loading":_vm.loading,"headers":_vm.headers,"items":_vm.apportionments,"options":_vm.pagination,"items-per-page":20,"footer-props":{
              'items-per-page-options': [10, 20, 50, 75]
            }},on:{"update:options":[function($event){_vm.pagination=$event},_vm.loadApportionments]},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date))+" ")]}},{key:"item.typeOfApportionment.description",fn:function(ref){
            var item = ref.item;
return [_c('v-chip',{attrs:{"color":item.typeOfApportionment.color,"label":"","outlined":"","small":""}},[_vm._v(" "+_vm._s(item.typeOfApportionment.description)+" ")])]}},{key:"item.estimatedValue",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.estimatedValue))+" ")]}},{key:"item.realizedValue",fn:function(ref){
            var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("currency")(item.realizedValue))+" ")]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":"","disabled":item.smsSent},on:{"click":function($event){return _vm.openDialogSendSms(item)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-message-text')}})],1),_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){return _vm.openDialog(item.typeOfApportionment, item)}}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-pencil')}})],1),_c('v-btn',{attrs:{"icon":"","small":""}},[_c('v-icon',{attrs:{"small":""},domProps:{"textContent":_vm._s('mdi-delete')},on:{"click":function($event){return _vm.clickDelete(item)}}})],1)]}}],null,true)})],1)],1)],1)],1),_c('dialog-apportionment',{attrs:{"type-of-apportionment":_vm.selectedTypeOfApportionment,"apportionment":_vm.selectedApportionment},on:{"save":_vm.loadApportionments},model:{value:(_vm.showDialog),callback:function ($$v) {_vm.showDialog=$$v},expression:"showDialog"}}),_c('dialog-delete',{attrs:{"loading":_vm.loadingDelete},on:{"confirm":_vm.deleteItem},model:{value:(_vm.showDialogDelete),callback:function ($$v) {_vm.showDialogDelete=$$v},expression:"showDialogDelete"}}),_c('dialog-filters',{on:{"filter":_vm.filter},model:{value:(_vm.showDialogFilters),callback:function ($$v) {_vm.showDialogFilters=$$v},expression:"showDialogFilters"}}),_c('dialog-send-sms',{attrs:{"apportionment":_vm.selectedApportionmentForSms},on:{"send":_vm.loadApportionments},model:{value:(_vm.showDialogSendSms),callback:function ($$v) {_vm.showDialogSendSms=$$v},expression:"showDialogSendSms"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }